// CSS
import '../../sass/third-party/rousquille.scss'
import AlphaFilters from '@nematis/wp-alpha/assets/js/abstracts/classes/alpha-filters'

AlphaFilters.addFilter('svgComponent.copyMode', (copyMode, svgComponentEl) => {
  if (svgComponentEl.closest('#rousquilleRoot')) {
    copyMode = 1
  }

  return copyMode
}, 'child')

